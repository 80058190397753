import React from 'react';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Message from './components/Message';
// import { Box } from '@mui/system';

import {
  Hero,
  Benefits,
  Features,
  GetStarted,
  QuickStart,
  Services,
  Team,
  Reviews,
  Partners
} from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-W1G8LXHX5C']
    })
  ]
})
const analyticsa = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})
analytics.page()
analyticsa.page()

// /* Track a custom event */
// analytics.track('cartCheckout', {
//   item: 'pink socks',
//   price: 20
// })

// /* Identify a visitor */
// analytics.identify('G-W1G8LXHX5C', {
//   firstName: 'bill',
//   lastName: 'murray'
// })



const IndexView = () => {
  return (
    <Main>

         {/* <Container maxWidth={0.9} marginTop={1} paddingTop={'0 !important'} >
         
         </Container> */}
        <Container maxWidth={0.9} marginTop={0.3} paddingTop={'0 !important'}>
        <Message /><br/>
          <Services /><br/>
        </Container>

        <Container maxWidth={0.88} paddingTop={'0 !important'}  backgroundColor = '#306EFF' borderRadius = '5px'>
          <Grid container spacing={6}>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            >
             <GetStarted />
            </Grid>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            
              
            >
             <Partners />
            </Grid>
          </Grid>
        </Container>

        {/* <Container maxWidth={0.9} paddingTop={'0 !important'} >
          <GetStarted />
          </Container>
         <br/> */}
         <br/>
        <Container maxWidth={0.9}  paddingTop={'0 !important'}>
          <Benefits />
          </Container>
         
          <Container maxWidth={0.9} marginTop={1} paddingTop={'0 !important'}>
          <Features />
        </Container>
       
       
        {/* <Container>
          <Search />
        </Container> */}
     

        <Container maxWidth={0.9} marginTop={0.6} paddingTop={'0 !important'} >
          <QuickStart />
        </Container>
   
        <Container maxWidth={0.9} marginTop={0.6} paddingTop={'0 !important'} id='activities'>
       <Team/>
        </Container>
    
        <Container maxWidth={0.9} marginTop={0.6} paddingTop={'0 !important'}>
          <Reviews />
        </Container>
      
        <Container maxWidth={0.9} marginTop={0.6} paddingTop={'0 !important'} >
       <Hero/>
        </Container>
      
      
    </Main>
  );
};

export default IndexView;
