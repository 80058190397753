import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const QuickStart = () => {
  const [config, setConfig] = useState({ base_image_url: '' });

  useEffect(() => {
    fetch('/config.json') // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/home-static/static-images/circularr.webp`;
  const p2 = `${config.base_image_url}/home/home-static/static-images/Facilities.jpg`;
  const p3 = `${config.base_image_url}/home/home-static/static-images/SafeeLearning.webp`;
  const p4 = `${config.base_image_url}/home/home-static/static-images/transporation.webp`;

  const mock = [
    {
      media: p4,
      href: '/safe-transportation',
      title: 'Safe Transportation',
      subtitle:
        'The purpose of the school transport is to support transport for Children who reside remote from their nearest Primary and Post Primary school.',
    },
    {
      media: p2,
      href: '/facilities',
      title: 'Facilities',
      subtitle:
        'The classrooms are large and well ventilated with white/green boards and bulletin boards. Other facilities available include Art and Craft Room.',
    },
    {
      media: p3,
      href: '/safe-learning-environment',
      title: 'Safe Learning Environment',
      subtitle:
        'A positive academic enrolment begins with safe families and safe communities and statistically schools continue to be one of the most secure places for our children.',
    },
    {
      media: p1,
      href: '/circular',
      title: 'Circular',
      subtitle:
        'People learn through a circular process of action, conceptualization. It involves referring to previous experiences as well as anticipating outcomes.',
    },
  ];

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
          gutterBottom
          color="secondary"
        >
          {/* You can add a heading here if needed */}
        </Typography>
        <Typography fontWeight={700} variant="h4" align="center">
          More about NPS YPR
        </Typography>
      </Box>

      <Box data-aos="fade-up" maxWidth={{ xs: 420, sm: 620, md: 1 }} margin="0 auto">
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display="block"
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': { transform: `translateY(-${theme.spacing(1 / 2)})` },
                }}
              >
                <a href={item.href} style={{ textDecoration: 'none' }}>
                  <Card
                    sx={{
                      width: 1,
                      height: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundImage: 'none',
                    }}
                  >
                    <CardMedia
                      title={item.title}
                      image={item.media}
                      sx={{
                        position: 'relative',
                        height: { xs: 240, sm: 340, md: 280 },
                        overflow: 'hidden',
                      }}
                    >
                      {/* Optional SVG for design */}
                      <Box
                        component="svg"
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1921 273"
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          left: 0,
                          bottom: 0,
                          right: 0,
                          zIndex: 1,
                        }}
                      >
                        {/* <polygon fill={theme.palette.background.paper} points="0,273 1921,273 1921,0 " /> */}
                      </Box>
                    </CardMedia>

                    <CardContent>
                      <Typography variant="h6" gutterBottom align="left" sx={{ fontWeight: 700 }}>
                        {item.title}
                      </Typography>
                      <Typography align="left" color="text.secondary">
                        {item.subtitle}
                      </Typography>
                    </CardContent>

                    <Box flexGrow={1} />
                    {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                      <Button
                        size="large"
                        sx={{ marginTop: 2 }}
                        href={item.href}
                        endIcon={
                          <Box
                            component="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </Box>
                        }
                      >
                        Learn more
                      </Button>
                    </CardActions> */}
                  </Card>
                </a>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default QuickStart;
